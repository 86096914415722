
















































































































import { Vue } from 'vue-property-decorator';
import { commonModule } from '@/store/viewModule/common/commonModule.ts';
import { changePassword, signUpFromUrl } from '@/api/client';

interface DataType {
  teamId: string;
  email: string;
  currentPassword: string;
  newPassword: string;
  passwordConfirm: string;
  isValid: boolean;
}

export default Vue.extend({
  name: 'ChangePasswordContainer',

  data: (): DataType => {
    return {
      teamId: '',
      email: '',
      currentPassword: '',
      newPassword: '',
      passwordConfirm: '',
      isValid: false,
    };
  },

  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('validator.required'),
        email: (email) => {
          const emailRegExp = /^[A-Za-z0-9]{1}[A-Za-z0-9_._-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
          return emailRegExp.test(email) || this.$t('validator.email');
        },
        passwordLength: (password) => {
          return password.trim().length >= 8 || this.$t('validator.passwordLength');
        },
        password: (password) => {
          const passwordRegExp = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d!"#$%&'()\*\+\-\.,\/:;<=>?@\[\\\]^_`{|}~]{1,100}$/;
          return passwordRegExp.test(password) || this.$t('validator.passwordChars');
        },
      };
    },
    showLoader() {
      return commonModule.showLoader;
    },
  },

  methods: {
    async reset() {
      commonModule.setLoader(true);
      const changePasswordResponse = await changePassword({
        teamId: this.teamId,
        oldPassword: this.currentPassword,
        newPassword: this.newPassword,
      }).then((data) => {
        commonModule.setLoader(false);
        const response = data;
        if (response && response.sub) {
          this.$router.push({ name: 'signin' });
        } else if (data.error) {
          alert(this.$t('errorUnexpected'));
        } else {
          alert(this.$tc('errorUnexpected'));
        }
      }).catch(() => {
        commonModule.setLoader(false);
        alert(this.$t('errorUnexpected'));
      });
    },
    backToSignin() {
      commonModule.logout();
    },
  },

  async mounted() {
    const teamId = this.$route.query.teamId as string;
    const email = this.$route.query.email as string;
    if (teamId) {
      this.teamId = teamId || '';
    }
    if (email) {
      this.email = email || '';
    }
  },
});
