



import { Component, Prop, Vue } from 'vue-property-decorator';
import ChangePasswordContainer from '@/container/auth/ChangePasswordContainer.vue';
@Component({
  components: {
    ChangePasswordContainer,
  },
})
export default class ChangePasswordScene extends Vue {
}
